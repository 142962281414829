import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';
import { jwtDecode } from 'jwt-decode';
import './../css/customFormulario.css';
import './../css/SinglePost.css';

const SinglePost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://espawfinder.com/backend/upload/${id}`);
        setPost(response.data);
      } catch (error) {
        console.error('Error al obtener la publicación:', error);
      }
      setLoading(false);
    };
    fetchPost();
  }, [id]);

  const handleDelete = async () => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta publicación?');
    if (!confirmDelete) return;

    // Obtener el token JWT de localStorage
    const token = localStorage.getItem('token');
    let userId = null;

    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decodificar el token para obtener el userId
        userId = decodedToken.userId; // Extraer el userId del token
        console.log('userId extraído del token:', userId); // Verifica el userId extraído
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }

    // Si no hay userId, muestra un mensaje de advertencia
    if (!userId) {
      alert('No estás autenticado. No se puede eliminar la publicación.');
      return;
    }

    try {
      const response = await axios.delete(`https://espawfinder.com/backend/upload/${id}`, {
        data: { userId }, // Enviar el userId en el cuerpo de la solicitud DELETE
      });
      alert('Publicación eliminada correctamente.');
      navigate('/'); // Redirigir al home o a una página de confirmación
    } catch (error) {
      console.error('Error al eliminar la publicación:', error);

      // Mostrar detalles del error en el alert
      if (error.response) {
        // Error del servidor con respuesta
        alert(`Error al eliminar la publicación: ${error.response.status} - ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        // Solicitud enviada pero sin respuesta
        alert('Error al eliminar la publicación: No se recibió respuesta del servidor.');
      } else {
        // Otro tipo de error
        alert(`Error al eliminar la publicación: ${error.message}`);
      }
    }
  };

  if (loading) {
    return <p className="text-center mt-5">Cargando publicación...</p>;
  }

  if (!post) {
    return <p className="text-center mt-5">No se encontró la publicación.</p>;
  }

  const {
    title,
    breed,
    color,
    gender,
    age,
    size,
    petCondition,
    description,
    ownerName,
    ownerPhone,
    ownerEmail,
    ownerMessage,
    reward,
    imagePaths = [],
    status,
  } = post;

  const formatStatus = (status) => {
    switch (status) {
      case 'lost':
        return 'Mascota Perdida';
      case 'found':
        return 'Mascota Encontrada';
      case 'adoption':
        return 'En Adopción';
      case 'lookingForMate':
        return 'Busca Pareja';
      case 'solidarityHelp':
        return 'Ayuda Solidaria';
      default:
        return 'Estado Desconocido';
    }
  };

  return (
    <div className="centrar-todo">
      {/* Banner de la sección */}
      <div className="formulario jumbotron jumbotron-fluid">
        <div className="container">
          <div className="jumbo-heading" data-aos="fade-up">
            <h1 className="Busqueda">{formatStatus(status)}</h1>
            <div className="breadcrumb-container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{formatStatus(status)}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Contenido principal */}
      <div className="px-4 mt-2 mb-2">
        <div className="row" style={{ height: '100%', flexWrap: 'nowrap' }}>
          {/* Columna izquierda */}
          <div className="container-fluid-home card bg-light col-lg-9 d-flex flex-column adopt-card">
            <div className="row">
              <div className="col-md-5 singlepost">
                {imagePaths.length > 0 ? (
                  <img
                    src={`https://espawfinder.com/backend/uploads/${imagePaths[0]}`}
                    alt={title}
                    className="img-fluid rounded mb-3"
                    style={{ height: '300px', objectFit: 'cover' }}
                  />
                ) : (
                  <div className="text-center bg-light py-5 rounded">No hay imagen disponible</div>
                )}
              </div>
              <div className="card-body flex-grow-1 d-flex flex-column">
                <h5 className="adoption-header">{title}</h5>
                <ul className="list-unstyled post-details mb-3">
                  {breed && <li><strong>Raza:</strong> {breed}</li>}
                  {color && <li><strong>Color:</strong> {color}</li>}
                  {gender && <li><strong>Género:</strong> {gender}</li>}
                  {age && <li><strong>Edad:</strong> {age}</li>}
                  {size && <li><strong>Tamaño:</strong> {size}</li>}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12 singlepost">
                <h4>Descripción</h4>
                <p>{description || 'No se proporcionó una descripción.'}</p>
                {petCondition && (
                  <>
                    <h4>Condición</h4>
                    <p>{petCondition}</p>
                  </>
                )}
              </div>
            </div>
            {/* Botón para eliminar */}
            <div className="text-center my-4">
              <button className="btn btn-danger" onClick={handleDelete}>
                Eliminar Publicación
              </button>
            </div>
          </div>

          {/* Columna derecha */}
          <div className="col-lg-3 d-flex flex-column justify-content-start">
            <div className="card bg-light p-3 mb-3">
              <h5>Contacto</h5>
              <p>Nombre: <strong>{ownerName}</strong></p>
              <p>Teléfono: <strong>{ownerPhone}</strong></p>
              <p>Correo: <strong>{ownerEmail}</strong></p>
              {reward && <p>Recompensa: <strong>{reward}</strong></p>}
              <p>Mensaje: <strong>{ownerMessage}</strong></p>
            </div>
            <div className="card bg-light p-3 text-center">
              <h5 className="mb-4">Opciones de compartir</h5>
              <div className="d-flex flex-column align-items-center">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(
                    `¡Mira esta publicación en Espaw Finder! "${title}" está disponible. Puedes ayudar o adoptarla aquí.`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-block mb-2"
                  style={{ backgroundColor: '#3b5998', color: '#fff' }}
                >
                  <i className="fab fa-facebook mr-2"></i> Compartir en Facebook
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    `¡Mira esta publicación en Espaw Finder! "${title}" está disponible. Ayuda o adopta desde aquí.`
                  )}&url=${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-block mb-2"
                  style={{ backgroundColor: '#1da1f2', color: '#fff' }}
                >
                  <i className="fab fa-twitter mr-2"></i> Compartir en Twitter
                </a>
                <a
                  href={`https://wa.me/?text=${encodeURIComponent(
                    `¡Mira esta publicación en Espaw Finder! "${title}" está disponible. Puedes ayudar o adoptarla aquí.`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-block mb-2"
                  style={{ backgroundColor: '#25d366', color: '#fff' }}
                >
                  <i className="fab fa-whatsapp mr-2"></i> Compartir en WhatsApp
                </a>
                <a
                  href={`mailto:?subject=${encodeURIComponent(
                    `¡Mira esta publicación en Espaw Finder!`
                  )}&body=${encodeURIComponent(
                    `Hola, encontré esta publicación en Espaw Finder: "${title}" está disponible para ayuda o adopción. Puedes verla aquí.`
                  )}`}
                  className="btn btn-block mb-2"
                  style={{ backgroundColor: '#6c757d', color: '#fff' }}
                >
                  <i className="fas fa-envelope mr-2"></i> Compartir por Email
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SinglePost;
